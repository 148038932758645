















































































import {Component, Vue} from 'vue-property-decorator';
import VuePrintDn from 'vue-print-nb';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import {getTicket} from "@/api/degreeeEnglish/degreeeEnglish";
@Component({
  components: {
    VuePrintDn,
    myHeader,
    myFooter
  }
})
export default class DegreePrint extends Vue  {
  printObj={
    id: 'print-node'
  }
  data = {};
  disvbel = false;
  
  goBack() {
    this.$router.go(-1); // Navigate back to the previous page
  }

  created() {
    this.getData();
  }

  getData() {
    getTicket({}).then((res: any) => {
      if(res.code == 200){
        this.data = res.data;
        this.disvbel = true;
      }else{
        this.$message.error(res.msg);
        this.$router.go(-1);
      }
    });
  }
}
